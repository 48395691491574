import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { MetaInfo } from "src/types/meta";
import { EMEAHero, EMEAHeroData } from "src/components/emea/hero";
import { EMEAIntro, EMEAIntroData } from "src/components/emea/intro";
import {
	EMEALearnMore,
	EMEALearnMoreData,
} from "src/components/emea/learn-more";
import { EMEATeam, EMEATeamData } from "src/components/emea/team";
import {
	EMEACommunity,
	EMEACommunityData,
} from "src/components/emea/community";
import { EMEAEvents, EMEAEventsData } from "src/components/emea/events";

export interface EMEAProps {
	data: {
		main: {
			slug: {
				current: string;
			};
			title: string;
			hero: EMEAHeroData;
			intro: EMEAIntroData;
			learnMore: EMEALearnMoreData;
			team: EMEATeamData;
			community: EMEACommunityData;
			events: EMEAEventsData;
		};
		meta: {
			metaTitle?: string;
			metaDescription?: string;
			openImage?: any;
			twitterImage?: any;
		};
	};
	location: any;
}

const EMEATemplate = ({ location, data }: EMEAProps) => {
	const { main, meta } = data;

	// set fallback meta tags from content
	const metaInfo: MetaInfo = {
		metaTitle: meta?.metaTitle || main.title,
		metaDescription: meta?.metaDescription,
		openImage: meta?.openImage || main.hero.media.image,
		twitterImage:
			meta?.twitterImage || meta?.openImage || main.hero.media.image,
	};

	return (
		<RevealOnMount location={location}>
			<SEO
				metaInfo={metaInfo}
				pagePath={location?.pathname}
				slug={main.slug.current}
			/>
			<EMEAHero data={main.hero} />
			<div className="bg-cream z1">
				<EMEAIntro data={main.intro} />
				<EMEALearnMore data={main.learnMore} />
				<EMEATeam data={main.team} />
				<EMEACommunity data={main.community} />
				<EMEAEvents data={main.events} />
			</div>
		</RevealOnMount>
	);
};

export default EMEATemplate;
