import React, { useEffect, useState, useCallback } from "react";
import cx from "classnames";
import { Reveal } from "src/components/reveal";
import { Media } from "src/components/media";
import { BlockContent } from "src/components/block-content";
import { ArrowLink } from "src/components/arrow-link";

import * as styles from "./learn-more.module.css";

interface SectionInterface {
	_key: string;
	media: {
		image: {
			alt: string;
			asset: any;
		};
		video?: any;
	};
	copy: any[];
	link: any;
}

const Section = ({
	data,
	index,
}: {
	data: SectionInterface;
	index: number;
}) => {
	return (
		<div className={styles.section}>
			<div className={styles.sectionInner}>
				<div className={styles.mediaContainer}>
					<Media data={data.media} className={styles.media} />
				</div>
				<Reveal className={styles.sectionContent}>
					<BlockContent
						blocks={data.copy}
						className={cx("title ac x", styles.sectionCopy)}
					/>
					<ArrowLink link={data.link} className={styles.sectionLink} />
				</Reveal>
			</div>
		</div>
	);
};

export interface EMEALearnMoreData {
	sections: [SectionInterface];
}

export const EMEALearnMore = ({ data }: { data: EMEALearnMoreData }) => {
	const { sections } = data;

	return (
		<div className={styles.learnMore}>
			{sections && (
				<div>
					{sections.map((section, index) => (
						<Section key={section._key} data={section} index={index} />
					))}
				</div>
			)}
		</div>
	);
};
