import React from "react";
import { graphql } from "gatsby";
import EMEATemplate from "src/templates/emea";

export const query = graphql`
	query EMEAQuery {
		page: sanityEmea(_id: { regex: "/(drafts.|)emea/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 8 })
		}
	}
`;

const EMEAPage = (props: any) => {
	const { location } = props;
	const content = props.data.page.content;
	return <EMEATemplate location={location} data={content} />;
};

export default EMEAPage;
