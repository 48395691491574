import React from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";
import { Media, MediaInterface } from "src/components/media";
import { BlockContent } from "src/components/block-content";

import * as styles from "./hero.module.css";

export interface EMEAHeroData {
	headline: any[];
	media: MediaInterface;
}

export const EMEAHero = ({ data }: { data: EMEAHeroData }) => {
	const { ref, inView } = useInView({
		rootMargin: "400px 0px",
	});

	const { headline, media } = data;

	return (
		<div ref={ref} className={cx("ac c-white", styles.hero)}>
			{inView && <Media data={media} className={styles.backgroundMedia} />}
			<h1>
				<BlockContent blocks={headline} className={cx("display ac")} />
			</h1>
		</div>
	);
};
