import React from "react";
import cx from "classnames";
import { Container } from "src/components/container";
import { Link } from "src/components/link";
import { BlockContent } from "src/components/block-content";
import { Media } from "src/components/media";
import { Reveal } from "src/components/reveal";
import * as styles from "./events.module.css";

const Event = ({ data }: { data: any }) => {
	return (
		<Reveal>
			<div className={styles.event}>
				<h3 className={cx("title mb1", styles.title)}>{data.title}</h3>

				{data.description && (
					<BlockContent
						className={cx("small-body", styles.description)}
						blocks={data.description}
					/>
				)}

				{data.details && (
					<p className={cx("label", styles.details)}>
						{data.details.split("\n").map((item: string, key: string) => {
							return (
								<React.Fragment key={key}>
									{item}
									<br />
								</React.Fragment>
							);
						})}
					</p>
				)}
				<div>
					<span className="label">{data.label}</span>
					{data.link && (
						<Link link={data.link} type={data.link.linkType}>
							<span className="label underline-on-hover">
								{data.link.linkCopy}
							</span>
						</Link>
					)}
				</div>
			</div>
		</Reveal>
	);
};

export interface EMEAEventsData {
	backgroundImage: {
		asset: {
			_ref?: string | undefined;
			_id?: string | undefined;
			url: string;
		};
		alt?: string | undefined;
	};
	events: any[];
}

export const EMEAEvents = ({ data }: { data: EMEAEventsData }) => {
	const { backgroundImage, events } = data;
	return (
		<div className={cx(styles.eventsSection)}>
			<Media
				className={styles.backgroundImage}
				data={{ image: backgroundImage }}
			/>
			<div className={styles.events}>
				{events &&
					events.map((event) => <Event key={event._key} data={event} />)}
			</div>
		</div>
	);
};
