import React from "react";
import cx from "classnames";
import { BlockContent } from "src/components/block-content";
import { VideoCard } from "src/components/video-card";
import { Container } from "src/components/container";
import { Image } from "src/components/image";

import * as styles from "./team.module.css";

export interface EMEATeamData {
	data: {
		headline: any[];
		video?: {
			title?: string;
			url: string;
		};
		memberSpotlight: {
			show: boolean;
			title?: string;
			name?: string;
			role?: string;
			bio?: any[];
			portrait?: {
				alt: string;
				asset: {
					_id: string;
				};
			};
		};
	};
}

export const EMEATeam = ({ data }: EMEATeamData) => {
	const { headline, video, memberSpotlight } = data;
	return (
		<div className={styles.team}>
			<Container>
				<BlockContent blocks={headline} className={cx("ac headline")} />
				{video?.url && (
					<VideoCard
						className={styles.videoCard}
						title={video.title}
						url={video.url}
					/>
				)}
				{memberSpotlight?.show && (
					<div className={styles.memberSpotlight}>
						{memberSpotlight.title && (
							<h3 className={cx("label")}>{memberSpotlight.title}</h3>
						)}
						{memberSpotlight.name && (
							<h4 className={cx("title", styles.memberSpotlightName)}>
								{memberSpotlight.name}
							</h4>
						)}
						{memberSpotlight.role && (
							<span className={cx("small-body", styles.memberSpotlightRole)}>
								{memberSpotlight.role}
							</span>
						)}
						{memberSpotlight.portrait && (
							<div className={styles.portraitContainer}>
								<Image
									className={styles.portrait}
									imageId={memberSpotlight.portrait.asset._id}
									alt={memberSpotlight.portrait.alt || ""}
								/>
							</div>
						)}
						{memberSpotlight.bio && (
							<BlockContent
								blocks={memberSpotlight.bio}
								className={cx("small-body", styles.bio)}
							/>
						)}
					</div>
				)}
			</Container>
		</div>
	);
};
