import React from "react";
import cx from "classnames";
import { BlockContent } from "src/components/block-content";
import { PartnershipCarousel } from "src/components/partnership-carousel";

import * as styles from "./community.module.css";

export interface EMEACommunityData {
	data: {
		headline: any[];
		copy: any[];
		partners: any[];
	};
}

export const EMEACommunity = ({ data }: EMEACommunityData) => {
	const { headline, copy, partners } = data;
	return (
		<div className={styles.community}>
			<div className={styles.container}>
				<BlockContent
					blocks={headline}
					className={cx("small-headline", styles.headline)}
				/>
				{copy && (
					<BlockContent blocks={copy} className={cx("body", styles.copy)} />
				)}
			</div>
			{partners && (
				<div className={styles.partners}>
					<PartnershipCarousel data={{ partners }} />
				</div>
			)}
		</div>
	);
};
